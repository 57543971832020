import {Title} from "./StyledTtile";

const MainTitle = ({children}) => {

    const title = children.split(" ");
    const lastWord = title[title.length - 1];
    title.pop();
    const upWords = title.join(" ");

    return <Title><span>{upWords} </span>{lastWord}</Title>
}

export default MainTitle;
