import {BrowserRouter, Routes, Route} from 'react-router-dom';
import data from "./__mock__";
import Recommendation from "./pages/recommendation";
import "./main.css"

const App = () => {

    return (
        <BrowserRouter>
            <div className="main-container">
            <Routes>
                <Route path={"/alimentos"} element={<Recommendation data={data.Recomendaciones} type="columns"/> }/>
                <Route path={"/servicios"} element={<Recommendation data={data["General services"]} type="grid"/> }/>
                <Route path={"/parrilla"} element={<Recommendation data={data["Stove guide"]} type="guide"/>} />
                <Route path={"/emergencias"} element={<Recommendation data={data.Emergencies} type="list"/>}/>
                <Route path={"/lockers"} element={<Recommendation data={data["Define password"]} type="guide"/>}/>
                <Route path={"/lavadora"} element={<Recommendation data={data["Laundry guide"]} type="laundry"/>}/>
                <Route path={"/habitacion"} element={<Recommendation data={data["Room services"]}/>}/>
            </Routes>
            </div>
        </BrowserRouter>
    )
}

export default App;
