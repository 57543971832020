import styled from "styled-components";
import colors from "../../colors";

const Title = styled.h1`
  color: ${colors.golden["500"]};
  margin: 0;
  font-size: 28px;
  text-align: center;
  
  span{
    display: block;
    font-size: 24px;
    font-weight: normal;
    text-align: center;
    line-height: 1.5;
    margin-top: 0;
  }
`

export {
    Title
}
