import styled from "styled-components";
import colors from "../../colors";

const ColumnContainer = styled.article`
  display: flex;
  flex-direction: ${props => props.odd === 1? "row": "row-reverse"};
  gap: 12px;
  margin-block: 20px 40px;
  min-height: 220px;
  max-height: 220px;
`

const ColumnImage = styled.img`
  display: block;
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 12px;
  background-color: white;
  overflow: hidden;
`

const ColumnTitle = styled.h2`
  margin-block: 0;
  font-size: 18px;
  color: ${colors.golden["500"]}
`

const ColumnDescription = styled.p`
  color: white;
  font-size: 14px;
  line-height: 1.5;
  margin-block: 5px;
`

const ColumnLink = styled.a`
  color: ${colors.magenta["500"]};
  text-decoration: none;
  font-weight: bolder;
  font-size: 14px;
`



export {
    ColumnContainer,
    ColumnImage,
    ColumnTitle,
    ColumnDescription,
    ColumnLink
}
