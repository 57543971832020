import MainTitle from "../components/MainTitle";
import Description from "../components/Description";
import Card from "../components/Card";
import Column from "../components/Column";
import List from "../components/List";
import Guide from "../components/Guide";
import datos from "./../__mock__/"
import {Helmet} from "react-helmet"

const Recommendation = ({data, type}) => {
    return <div className="container">
        <Helmet>
            <title>{data["section-name"]}</title>
        </Helmet>

        <MainTitle>{data["section-name"]}</MainTitle>
        {
            data.description && <Description>{data.description}</Description>
        }
        {
            type === "grid" && <Card items={data.items}/>
        }
        {
            type === "columns" && data.items.map((el, index) => {
                return <Column
                    key={`${el.name}_${index}`}
                    odd={(index + 1)%2}
                    title={el.name}
                    image={el.image}
                    address={el.link}
                    description={el.description}
                />
            })
        }
        {
            type === 'list' && data.items.map((el, index) => {
                return <List
                    key={`${el.name}_${index}`}
                    name={el.name}
                    phone={el.phone}
                />
            })
        }
        {
            type === 'guide' && data.items.map((el, index) => {
                return <Guide
                    key={`${el.name}_${index}`}
                    step={el.name}
                    description={el.description}
                    illustration={el.image}
                />
            })
        }
        {
            type === 'laundry' && <div>
                {
                    data.items.map((el, index) => {
                        return <Guide
                            key={`${el.name}_${index}`}
                            step={el.name}
                            description={el.description}
                            illustration={el.image}
                        />
                    })
                }
                <MainTitle>{datos["Drying machine"]["section-name"]}</MainTitle>
                <Description>{datos["Drying machine"].description}</Description>
                {
                    datos["Drying machine"].items.map((el, index) => {
                        return <Guide
                            key={`${el.name}_${index}`}
                            step={el.name}
                            description={el.description}
                            illustration={el.image}
                        />
                    })
                }
            </div>
        }
    </div>

}

export default Recommendation;
