const assistance = {
  "Recomendaciones": {
    "section-name": "Recomendaciones para comer",
    "items": [
      {
        name: "Daca Mariba",
        description: "Sí lo que deseas es disfrutar de unos ricos mariscos a tan solo 5 minutos de distancia, te recomendamos Daca Mariba.  Con un horario de atención de 11:00 am a 7:00 pm.",
        link: "https://maps.app.goo.gl/sAGyLoyqBsVLQj2f7",
        image: "./static/daca_mariba.png"
      },
      {
        name: "Subway",
        description: "Si lo tuyo es lo rápido y saludable. Subway 20 de nov. te espera... disfruta de su deliciosos menú de 8:00 am a 11:00 pm.",
        link: "https://maps.app.goo.gl/8hyNyZm5HTUzhroK6",
        image: "./static/subway.png"
      },
      {
        name: "Mr Tamales",
        description: "Disfruta de unos ricos y tradicionales tamales a una cuadra de tu estancia. “Mr Tamales” en un horario de 12:00 pm a 11:00 pm.",
        link: "https://maps.app.goo.gl/EBg6oDsZ7p2JDCTh8",
        image: "./static/mr_tamales.png"
      },
      {
        name: "El caminero",
        description: "¿Te gustán los tacos? Te recomendamos disfrutar de los deliciosos tacos “El Camionero” para degustar una deliciosa comida en un horario de 5:00 pm a 12:30 am.",
        link: "https://maps.app.goo.gl/SReMinBp9nudERoNA",
        image: "./static/el_caminero.png"
      },
      {
        name: "Chilaquiles and Coffee",
        description: "Para un rico y casero desayuno disfruta de “Chilaquiles and Coffe”  en un horario de atención de 8:00 am a 1:00 pm",
        link: "https://maps.app.goo.gl/afT5dZqtUPzhTdJW9",
        image: "./static/chilaquiles_coffee.png"
      },
      {
        name: "Hamburguesas Burger",
        description: "Para un antojo rápido y tradicional “Hamburguesas Burger” tiene para ti un delicioso menú, encuentralos de 8:00 pm a 12:00 am.",
        link: "https://maps.app.goo.gl/Dinh8z84yADeAh1x5",
        image: "./static/hamburguesas_burger.png"
      },
      {
        name: "Mesa de Navar",
        description: "Encuentra unos deliciosos cortes de carne y especialidades a solo 3 minutos de distancia, “Mesa de Navar” te ofrece esto y mucho más... En un Horario de 6:30 pm a 10:30 pm.",
        link: "https://maps.app.goo.gl/49hjLk8bdpTypz6P9",
        image: "./static/mesa_navar.png"
      },
      {
        name: "Carnicería Kelbos",
        description: "Si lo tuyo es preparar algo en tu lugar de alojamiento “Carniceria Kelbos” tiene para ti todo lo indispensable para preparar tu propia comida casera. En un horario de 8:30 a.m a 6:00 p.m",
        link: "https://maps.app.goo.gl/42H677uKaNae7irF8",
        image: "./static/carniceria_kelbos.png"
      },
      {
        name: "Cúrcuma",
        description: "Recibe tus mañanas con un rico y saludable desayuno acompañado de un rico jugo natural y postre. “Curcuma” te espera en un horario de 8:00 am a 3:00 pm.",
        link: "https://maps.app.goo.gl/JkC9teeiGdBfCx4U6",
        image: "./static/curcuma.png"
      },
      {
        name: "Ciao Bella",
        description: "Para un rico antojo “Ciao Bella” te ofrece una variedad de cafes y postres para deleitar tu paladar. A tan solo 5 minutos de tu estancia en un horario de 10:00 am a 10:00 p.m",
        link: "https://maps.app.goo.gl/uDRHUSYiBN6KAczr8",
        image: "./static/ciao_bella.png"
      }
    ]
  },
  "Room services": {
    "section-name": 'Servicios de la habitación',
    "items": [
      {
        name: "Aplicación de televisión para android",
        description: "Descarga la app para poder controlar la televisión desde tu dispositivo",
        link: "",
        image: "./image.jpg"
      },
      {
        name: "Aplicación de televisión para iOS",
        description: "Descarga la app para poder controlar la televisión desde tu dispositivo",
        link: "",
        image: "./image.jpg"
      },
      {
        name: "Aplicación de aire acondicionado para android",
        description: "Descarga la app para poder controlar el aire acondicionado desde tu dispositivo",
        link: "",
        image: "./image.jpg"
      },
      {
        name: "Aplicación de aire acondicionado para iOS",
        description: "Descarga la app para poder controlar el aire acondicionado desde tu dispositivo",
        link: "",
        image: "./image.jpg"
      }
    ]
  },
  "General services": {
    "section-name": "Servicios generales",
    "description": "Estos son algunos de los lugares más cercanos a tu estancia que puedes encontrar",
    "items": [
      {
        icon: "./static/Simi-3545843649.png",
        name: "Farmacias Similares",
        tipo: "Farmacia",
        address: "https://maps.google.com",
        phone: "618000000"
      },
      {
        icon: "./static/cruz-roja.png",
        name: "Cruz Roja Mexicana",
        tipo: "Atención Médica",
        address: "https://maps.app.goo.gl/McaNSe3do4kztGua8",
        phone: "6188173535"
      },
      {
        icon: "./static/SaludDigna.png",
        name: "Salud Digna",
        tipo: "Laboratorio clínico",
        address: "https://maps.google.com",
        phone: "618000000"
      },
      {
        icon: "./static/Oxxo_Logo.svg.png",
        name: "OXXO",
        tipo: "Tienda de conveniencia",
        address: "https://maps.google.com",
        phone: "618000000"
      },
      {
        icon: "./static/ckclub.png",
        name: "Circle K",
        tipo: "Tienda de conveniencia",
        address: "https://maps.google.com",
        phone: "618000000"
      },
      {
        icon: "./static/DHL-Symbol.png",
        name: "DHL",
        tipo: "Paquetería",
        address: "https://maps.app.goo.gl/LmSmArRbRL9NM9Yu9",
        phone: "8007656345"
      },
      {
        icon: "./static/FedEx-logo.png",
        name: "Fedex",
        tipo: "Paquetería",
        address: "https://maps.app.goo.gl/EcHMwfhAj5u34Rag7",
        phone: "6188293344"
      },
      {
        icon: "./static/telcel.png",
        name: "Telcel",
        tipo: "Servicio de telefonía móvil",
        address: "https://maps.app.goo.gl/DDPAcqcekoU4yCek6",
        phone: "6181066044"
      },
      {
        icon: "./static/BBVA_2019.svg.png",
        name: "BBVA",
        tipo: "Banco",
        address: "https://maps.app.goo.gl/3F9M2jaDFt9gWQcC9",
        phone: "618000000"
      },
      {
        icon: "./static/img-santander-logo.png",
        name: "Santander",
        tipo: "Banco",
        address: "https://maps.app.goo.gl/DWSi5KFQMV8vM9MQ8",
        phone: "6188180187"
      },
      {
        icon: "./static/descarga.jpeg",
        name: "EustOil",
        tipo: "Gasolinería",
        address: "https://maps.app.goo.gl/GZX7tXcyuvKJRGR49",
        phone: "618000000"
      },
      {
        icon: "./static/scissors-free-icon-font.png",
        name: "Barbería",
        tipo: "Barbería",
        address: "https://maps.google.com",
        phone: "618000000"
      },
      {
        icon: "./static/shield-check-free-icon-font.png",
        name: "Lavandería",
        tipo: "Lavandería",
        address: "https://maps.google.com",
        phone: "618000000"
      },
      {
        icon: "./logo.png",
        name: "Hospital San Jorge",
        tipo: "Atención Médica",
        address: "https://maps.google.com",
        phone: "618000000"
      }
    ]
  },
  "Stove guide": {
    "section-name": "Guía de uso parrilla eléctrica",
    "description":"En este pequeño apartado encontrarás una guía básica para el uso de tu estufa de inducción.Cualquier duda no dudes en contactarnos.",
    "items": [
      {
        name: 1,
        description: "Coloque una olla o sartén en el centro de la zona de calentamiento.\n Si no hay ningún objeto en la zona de calentamiento, aparecerá en el panel de control, la leyenda E0. \n Ahí aparecerá la temperatura y con el uso de los botones de más / menos podrá modular la temperatura según lo desee.",
        image: "./static/mas_menos.png"
      },
      {
        name: 2,
        description: "Programe el tiempo que quiere que su parrilla de inducción caliente. El tiempo va indicado en un intervalo entre 0 y 180 minutos.",
        image: "./static/tiempo.png"
      },
      {
        name: 3,
        description: "Oprima el botón de bloqueo por 1 segundo, para bloquear el panel de control. Para desbloquear presione nuevamente 3 segundos en el botón de bloqueo",
        image: "./static/bloqueo.png"
      },
      {
        name: 4,
        description: "Para apagar la parrilla de inducción oprima el botón de Encendido/Apagado.",
        image: null
      },
      {
        name: 5,
        description: "Antes de limpiarla espere a que su parrilla de inducción se enfrié.",
        image: null
      }
    ]
  },
  "Emergencies": {
    "section-name": "Números de emergencia Durango",
    description: "Si cuentas con una red extranjera no olvides utilizar primero nuestro código del país (+)52 código de área  y numero, para realizar una llamada con éxito.",
    "items": [
      {
        name: "Emergencias",
        phone: "911"
      },
      {
        name: "Cruz Roja",
        phone: "6188173444"
      },
      {
        name: "Protección Civil / Bomberos",
        phone: "6188144555"
      },
      {
        name: "Seguridad Pública",
        phone: "6181378112"
      },
      {
        name: "Departamento de tránsito",
        phone: "6188101994"
      },
      {
        name: "Policía Federal de Caminos",
        phone: "6188143621"
      },
      {
        name: "Policía Judicial del Estado",
        phone: "6188143620"
      },
      {
        name: "Fiscalía del Estado de Durango",
        phone: "6188176506"
      },
      {
        name: "Secretaría de Salubridad y Asistencia",
        phone: "6188175550"
      },
      {
        name: "Incendios Forestales en Estado de Durango",
        phone: "6181500300"
      },
      {
        name: "Seguridad al Turista",
        phone: "6188174760"
      },
      {
        name: "CFE",
        phone: "6188171138"
      }
    ]
  },
  "Define password": {
    "section-name": "Definir contraseña temporal",
    "items": [
      {
        name: 1,
        description: "Presiona OK, este emitirá un \"bip\"",
        image: null
      },
      {
        name: 2,
        description: "Introduce tu clave única de 4 dígitos, presiona \"ok\" nuevamente y este se abrirá",
        image: null
      },
      {
        name: 3,
        description: "Sujeta la perilla y gírala hacia el lado derecho, abre la puerta, introduce tus pertenencias, cierra la puerta y gira la perilla en sentido contrario para cerrar.",
        image: null
      },
      {
        name: "nota",
        description: "(Anota tu contraseña en tu teléfono para poder acceder a tu locker)",
        image: null,
      },
    ]
  },
  "Laundry guide": {
    "section-name": "Guía de uso lavadora",
    "items": [
      {
        name: 1,
        description: "Levanta la tapa de la lavadora e introduce las prendas.",
        image: null,
      },
      {
        name: 2,
        description: "Vierte una tapa de detergente y suavizante en el dispensador correspindiente dentro de la lavadora.",
        image: null,
      },
      {
        name: 3,
        description: "Selecciona el programa de lavado correspondiente a tus prendas.",
        image: null,
      },
      {
        name: "nota",
        description: "Al momento de iniciar el proceso de lavado la tapa se bloqueará en automático, si deseas desbloquearla solo oprime el botón de pausa.",
        image: null,
      },
    ]
  },
  "Drying machine": {
    "section-name": "Guia de uso secadora",
    "items": [
      {
        name: 1,
        description: "Introduce tu ropa humeda.",
        image: null,
      },
      {
        name: 2,
        description: "Selecciona el tiempo de secado.",
        image: null,
      },
      {
        name: "nota",
        description: "Recomendación: De 1 a 5 prendas utiliza el tiempo de 15-20 minutos. De 6 prendas en adelante utiliza el tiempo de 20-30 minutos.\nPara cargas muy pesadas el límite será de 40 minutos.",
        image: null,
      },
    ]
  }
}

export default assistance;
