import {GuideContainer, GuideTitle, GuideDescription, GuideIllustration} from "./GuideStyle";

const Guide = ({step, description, illustration}) => {
    return <GuideContainer>
        {
            step === 'nota'?<GuideTitle aria-label={`nota`}>💡</GuideTitle> :<GuideTitle aria-label={`paso ${step}`}>{step}</GuideTitle>
        }
        <GuideDescription>{description}</GuideDescription>
        {illustration && <GuideIllustration src={illustration} alt="" aria-hidden={"true"}/>}
    </GuideContainer>
}

export default Guide;
