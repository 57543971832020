import styled from "styled-components";
import colors from "../../colors";

const GuideContainer = styled.article`
  padding: 10px;
  border-bottom: 1px dashed ${colors.golden["500"]}44;
  margin-block: 30px;
`

const GuideTitle = styled.h2`
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  margin-inline: auto;
  background-color: ${colors.golden["500"]};
  border-radius: 100px;
  color: ${colors.carbon["500"]}
`

const GuideDescription = styled.p`
  color: white;
  text-align: center;
  line-height: 1.5;
`

const GuideIllustration = styled.img`
  width: 70%;
  display: block;
  margin: 0 auto;
`

export {
    GuideContainer,
    GuideTitle,
    GuideDescription,
    GuideIllustration
}
