const colors = {
  carbon: {
    '500': '#3C4245'
  },
  green: {
    '500': '#719192'
  },
  golden: {
    '500': '#DFCDC3'
  },
  gray: {
    '500': '#5F6769'
  },
  magenta: {
    '500': '#C94277',
  },
  white: '#ffffff',
}

export default colors;
