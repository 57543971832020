import styled from "styled-components";

const StyledDescription = styled.p`
  color: #ccc;
  line-height: 1.5;
  text-align: center;
  padding-inline: 10px;
`

export default StyledDescription;
