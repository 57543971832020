import styled from "styled-components";
import colors from "../../colors";

const CardGrid = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px 10px;
`

const CardImage = styled.img`
  width: 80px;
  height: 80px;
  object-fit: contain;
  border-radius: 80px;
  display: block;
  background-color: white;
  margin-bottom: 8px;
  padding: 10px;
  overflow: auto;
  
`

const CardTitle = styled.h2`
  font-size: 20px;
  color: ${colors.golden["500"]};
  font-weight: bolder;
  text-align: center;
  margin: 0;
  height: 47px;
  display: flex;
  align-items: center;
`

const CardDescription = styled.p`
  font-size: 14px;
  font-style: italic;
  font-weight: lighter;
  text-align: center;
  margin-block: 5px;
  color: white;
  height: 33px;
  display: flex;
  align-items: center;
`

const CardButton = styled.a`
  display: inline-block;
  text-decoration: none;
  padding: 6px 12px;
  background-color: ${colors.magenta["500"]};
  color: white;
  font-weight: bold;
  border-radius: 50px;
  margin-block: 10px;
`

const CallButton = styled.a`
  display: block;
  margin: 0;
  color: ${colors.golden["500"]};
  text-align: center;
  text-decoration: none;
`

const CardContainer = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export {
    CallButton,
    CardGrid,
    CardImage,
    CardTitle,
    CardDescription,
    CardContainer,
    CardButton
}
