import {CardTitle, CardContainer, CardImage, CardDescription, CallButton, CardGrid, CardButton} from "./StyledCard";

const Card = ({items}) => {

    return <CardGrid>
        {
            items.map((it, index) => {
                return <CardContainer key={`${it}_${index}_${Math.floor(Math.random() * 1000)}`}>
                    <CardImage src={it.icon} alt={it.name}/>
                    <CardTitle>{it.name}</CardTitle>
                    <CardDescription>{it.tipo}</CardDescription>
                    <CardButton href={it.address}>¿Cómo llegar?</CardButton>
                    <CallButton href={`tel:${it.phone}`}>Llamar</CallButton>
                </CardContainer>
            })
        }
    </CardGrid>
}

export default Card;
