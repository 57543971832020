import styled from "styled-components";
import colors from "../../colors";

const ListContainer = styled.a`
  display: flex;
  border: 1px solid #fff2;
  border-radius: 15px;
  padding: 15px;
  text-decoration: none;
  margin-block: 20px;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
`

const ListButton = styled.p`
  background-color: ${colors.magenta["500"]};
  color: #fff9;
  display: inline-block;
  padding: 6px 12px;
  border-radius: 50px;
`

const ListText = styled.p`
  color: ${colors.golden["500"]};
  font-weight: bold;
`



export {
    ListContainer,
    ListButton,
    ListText

}
