import {ColumnContainer, ColumnImage, ColumnTitle, ColumnDescription, ColumnLink} from "./ColumnElements";

const Column = ({image, description, title, address, odd}) => {

    return <ColumnContainer odd={odd}>
        <div>
            <ColumnImage src={image} alt={title}/>
        </div>
        <div>
            <ColumnTitle>{title}</ColumnTitle>
            <ColumnDescription>{description}</ColumnDescription>
            <ColumnLink href={address}>¿Cómo llegar?</ColumnLink>
        </div>
    </ColumnContainer>
}

export default Column;
