import {ListContainer, ListButton, ListText} from "./ListStyled"

const List = ({phone, name}) => {
    return <ListContainer href={`tel:${phone}`}>
        <ListText>{name}</ListText>
        <ListButton>Llamar</ListButton>
    </ListContainer>
}

export default List;
