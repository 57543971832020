import StyledDescription from "./StyledDescription";

const Description = ({children}) => {

    return <StyledDescription>
        {children}
    </StyledDescription>
}

export default Description;
